import StorageInterface from "./StorageInterface";
import StorageItemWithExpiration from "./StorageItemWithExpiration";
import ReactiveObject from "../ReactiveObject";
import Delay from "~/ts/library/Delay";
import Dictionary from "~/ts/library/Dictionary";
import ConsoleWrapper from "~/ts/library/Console";
import ObjectHelper from "~/ts/library/ObjectHelper";


let storage = ReactiveObject.make({});
//console.log("reactive storage", storage);
let eventListeners: ((key: string, e: StorageEvent) => void)[] = [];

abstract class ReactiveStorage implements StorageInterface {
    protected prefix: string = "";
    private wasErrorOnSet: boolean = false;

    abstract getType(): string;

    getDebugInfo(): Dictionary<any> {
        return {
            wasErrorOnSet: this.wasErrorOnSet
        }
    }

    public setPrefix(prefix: string) {
        if (prefix.length) {
            prefix += "/";
        }
        this.prefix = prefix;
        for (let key of storage.keys()) {
            this.get(key, true);
        }
        return this;
    }

    protected get isForceReloadAvailable(): boolean {
        return true;
    }


    public static addEventListener(callback: (key: string, e: StorageEvent) => void) {
        eventListeners.push(callback);
    }

    protected static fireEvent(key: string, e: StorageEvent): void {
        for (let i = 0; i < eventListeners.length; i++) {
            eventListeners[i](key, e);
        }
    }

    public get(key: string, forceReload?: boolean): any | null {
        let reactiveStorageValue = storage.get(key);
        let hasInReactiveStorage = ReactiveStorage.hasInReactiveStorage(key);
        if ((forceReload && this.isForceReloadAvailable) || !hasInReactiveStorage) {
            let realStorageValue = this.getFromStorage(key);
            if (JSON.stringify(realStorageValue) !== JSON.stringify(reactiveStorageValue) || !hasInReactiveStorage) {
                ReactiveStorage.setInReactiveStorage(key, realStorageValue);
                return this.get(key);
            }
        }
        if (typeof reactiveStorageValue == "object") {
            reactiveStorageValue = ObjectHelper.jsonClone(reactiveStorageValue);
        }
        return reactiveStorageValue;
    }

    private getStorageItemWithExpiration(key: string): StorageItemWithExpiration | null {
        let result: StorageItemWithExpiration = null;
        let expirationItem = this.get(key) as (null | StorageItemWithExpiration);
        if (expirationItem != null) {
            if (expirationItem.hasOwnProperty("value") && expirationItem.hasOwnProperty("ttl")) {
                expirationItem = new StorageItemWithExpiration(expirationItem.value, expirationItem.ttl, false);
                if (expirationItem.isAlive()) {
                    this.setexRemove(key, expirationItem.getCurrentTtl());
                    result = expirationItem;
                } else {
                    this.remove(key);
                }
            } else {
                result = new StorageItemWithExpiration(expirationItem, 0);
            }
        }
        return result;
    }

    public getCurrentTtl(key: string): number | null {
        let ttl = this.getStorageItemWithExpiration(key)?.getCurrentTtl();
        return ttl ? ttl : null;
    }

    public getex(key: string): any | null {
        return this.getStorageItemWithExpiration(key)?.getValue();
        /*
        let result: any = null;
        let expirationItem = this.get(key) as (null | StorageItemWithExpiration);
        if (expirationItem != null) {
            if (expirationItem.hasOwnProperty("value") && expirationItem.hasOwnProperty("ttl")) {
                expirationItem = new StorageItemWithExpiration(expirationItem.value, expirationItem.ttl, false);
                if (expirationItem.isAlive()) {
                    this.setexRemove(key, expirationItem.getCurrentTtl());
                    result = expirationItem.getValue();
                } else {
                    this.remove(key);
                }
            } else {
                result = expirationItem;
            }
        }
        return result;
        */
    }

    public remove(key: string): void {
        this.removeFromStorage(key);
        ReactiveStorage.removeFromReactiveStorage(key);
    }

    private isErrorLogged = false;

    public set(key: string, value: any): void {
        ReactiveStorage.setInReactiveStorage(key, value);
        try {
            this.setToStorage(key, value, true);
        } catch (e) {
            this.wasErrorOnSet = true;
            if (!this.isErrorLogged) {
                this.isErrorLogged = true;
                ConsoleWrapper.error("Ошибка вставки в хранилище: ", e);
            }
        }
    }

    public isErrorOnSet(): boolean {
        return this.wasErrorOnSet;
    }

    public setex(key: string, value: any, ttl: number): void {
        this.set(key, new StorageItemWithExpiration(value, ttl));
        this.setexRemove(key, ttl);
    }

    private async setexRemove(key: string, ttl: number) {
        try {
            await Delay.make(ttl, "setexRemove_" + key, true);
            this.getex(key);
        } catch (e) {

        }
    }

    protected static hasInReactiveStorage(key: string): boolean {
        return storage.has(key);
    }

    protected static setInReactiveStorage(key: string, value: any): void {
        storage.set(key, value);
    }

    protected static removeFromReactiveStorage(key: string) {
        storage.set(key, null);
        storage.remove(key);
    }

    protected abstract getFromStorage(key: string): any | null;

    protected abstract setToStorage(key: string, value: any, throwError: boolean): void;

    protected abstract removeFromStorage(key: string): void;

    public abstract isAvailable(): boolean;

    public abstract isPersistent(): boolean;
}


export default ReactiveStorage;