import Dictionary from "~/ts/library/Dictionary";
import {SvgIcon} from "~/chat/ts/data/ISiteParams";

export const SVG_ICONS: Dictionary<Dictionary<SvgIcon>> = {
    /*
    paperclip: {
        light: {
            width: 512,
            height: 512,
            content: "M149.106 512c-33.076 0-66.153-12.59-91.333-37.771-50.364-50.361-50.364-132.305-.002-182.665L319.842 29.498c39.331-39.331 103.328-39.331 142.66 0 39.331 39.332 39.331 103.327 0 142.657l-222.63 222.626c-28.297 28.301-74.347 28.303-102.65 0-28.3-28.301-28.3-74.349 0-102.649l170.301-170.298c4.686-4.686 12.284-4.686 16.97 0l5.661 5.661c4.686 4.686 4.686 12.284 0 16.971l-170.3 170.297c-15.821 15.821-15.821 41.563.001 57.385 15.821 15.82 41.564 15.82 57.385 0l222.63-222.626c26.851-26.851 26.851-70.541 0-97.394-26.855-26.851-70.544-26.849-97.395 0L80.404 314.196c-37.882 37.882-37.882 99.519 0 137.401 37.884 37.881 99.523 37.882 137.404.001l217.743-217.739c4.686-4.686 12.284-4.686 16.97 0l5.661 5.661c4.686 4.686 4.686 12.284 0 16.971L240.44 474.229C215.26 499.41 182.183 512 149.106 512z"
        }
    },
    times: {
        light: {
            width: 320,
            height: 512,
            content: "M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
        }
    },
    smile: {
        light: {
            width: 496,
            height: 512,
            content: "M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216zm90.2-146.2C315.8 352.6 282.9 368 248 368s-67.8-15.4-90.2-42.2c-5.7-6.8-15.8-7.7-22.5-2-6.8 5.7-7.7 15.7-2 22.5C161.7 380.4 203.6 400 248 400s86.3-19.6 114.8-53.8c5.7-6.8 4.8-16.9-2-22.5-6.8-5.6-16.9-4.7-22.6 2.1zM168 240c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32z"
        }
    },

     */
    'send-button': {
        regular: {
            width: 39,
            height: 36,
            content: "M36.9944 16.1793C38.5576 16.8898 38.5576 19.1102 36.9944 19.8207L3.3778 35.101C1.54621 35.9335 -0.229926 33.8449 0.886089 32.1709L9.59373 19.1094C10.0416 18.4376 10.0416 17.5624 9.59373 16.8906L0.886091 3.82913C-0.229923 2.15511 1.54621 0.0664553 3.3778 0.898995L36.9944 16.1793Z"
        }
    },
    'header-back': {
        light: {
            width: 32,
            height: 32,
            content: "M16.9051 2.64698C17.2624 2.22586 17.2107 1.59481 16.7896 1.2375C16.3684 0.880177 15.7374 0.931898 15.3801 1.35302L16.9051 2.64698ZM15.3801 30.9313C15.7374 31.3524 16.3684 31.4041 16.7896 31.0468C17.2107 30.6895 17.2624 30.0584 16.9051 29.6373L15.3801 30.9313ZM4.69198 16.7891L5.45449 16.1421L4.69198 16.7891ZM4.69198 15.4952L3.92948 14.8482L4.69198 15.4952ZM16.9051 29.6373L5.45449 16.1421L3.92948 17.4361L15.3801 30.9313L16.9051 29.6373ZM5.45449 16.1421L16.9051 2.64698L15.3801 1.35302L3.92948 14.8482L5.45449 16.1421ZM5.45449 16.1421L5.45449 16.1421L3.92948 14.8482C3.29616 15.5946 3.29616 16.6897 3.92948 17.4361L5.45449 16.1421Z"
        }
    },
    'bars': {
        light: {
            width: 448,
            height: 512,
            content: "M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"
        }
    },
    'chevron-down': {
        light: {
            width: 448,
            height: 512,
            content: "M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z"
        }
    }
};