import destroyWidget from "~/chat/ts/service/api/methods/Destroy";
import {ConfigStore} from "~/chat/ts/store/Config";
import ConsoleWrapper from "~/ts/library/Console";

export default async function reload(callback: (apiVariable: any) => void){
    try {
        let script: HTMLScriptElement = document.getElementById("supportScript") as any;
        if (script) {
            let src = script.src;
            if (src) {
                if ((window as any)[ConfigStore.setup.value.apiMethod]) {
                    var apiMethod = ConfigStore.setup.value.apiMethod;
                    await destroyWidget();

                    setTimeout(function () {
                        (function (d: HTMLDocument, w: any, m: string) {
                            (window as any).supportAPIMethod = m;
                            var s = d.createElement('script');
                            s.type = 'text/javascript';
                            s.id = 'supportScript';
                            s.charset = 'utf-8';
                            s.async = true;
                            s.src = src;
                            var sc = d.getElementsByTagName('script')[0];
                            w[m] = function () {
                                (w[m].q = w[m].q || []).push(arguments);
                            };
                            if (sc) sc.parentNode.insertBefore(s, sc);
                            else d.documentElement.firstChild.appendChild(s);
                            if (callback) {
                                callback(w[m]);
                            }
                        })(document, window, apiMethod);
                    }, 100);
                }
            } else {
                ConsoleWrapper.log("Не задан src");
            }
        } else {
            ConsoleWrapper.warn("#supportScript не найден");
        }
    } catch(e) {
        ConsoleWrapper.error(e);
    }
}