import Vue from "vue";
import ChatRootComponent from "~/chat/vue/ChatRootComponent.vue";
//@ts-ignore
import {directive as onClickaway} from 'vue-clickaway';
import ChatTranslatePlugin from "~/chat/ts/initialize/ChatVueTranslatePlugin";
import InlineWidgetWatcher from "~/chat/ts/service/InlineWidgetWatcher";
import {Intervals} from "~/ts/library/delay/Intervals";
import ChatComponentMount from "~/chat/ts/service/ChatComponentMount";
import DisposableEventsPlugin from "~/ts/vuePlugins/DisposableEventsPlugin";


let container = document.createElement("div");
let vueInstance: Vue;
let parentInterval: number;


Vue.directive('onClickaway', onClickaway);

export default class InitVue {

    public static init(): Vue | null {
        if (container) {
            Vue.use(ChatTranslatePlugin);
            Vue.use(DisposableEventsPlugin);

            (new InlineWidgetWatcher()).watch();

            let body = document.body;

            body.appendChild(container);
            //vueInstance = new ChatRootComponent();
            vueInstance = new Vue(ChatRootComponent);
            ChatComponentMount.mount(container, vueInstance, [], true);

            let parent: HTMLElement;
            let $el: Element;
            parentInterval = Intervals.set(() => {
                $el = vueInstance.$el.parentElement;
                if ($el) {
                    parent = $el.parentElement;
                    if (parent && parent != body) {
                        body.appendChild($el);
                    }
                }
            }, 1000);
            return vueInstance;
        }
        return null;
    }

    public static destroy() {
        if (vueInstance) {
            if (parentInterval) {
                Intervals.clear(parentInterval);
            }
            vueInstance.$destroy();
            try {
                let el = vueInstance.$el.parentElement;
                el.parentElement.removeChild(el);
            } catch (e) {

            }
            container = null;
            vueInstance = null
        }
    }
}

