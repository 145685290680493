import {CLIENT_ID_COOKIE} from "~/chat/ts/Constants";
import Random from "~/ts/library/Random";
import Cookie from "~/ts/library/Cookie";
import {ConfigStore} from "~/chat/ts/store/Config";
import {StorageInstance} from "~/ts/library/storage/StorageFactory";
import Dictionary from "~/ts/library/Dictionary";
import Delay from "~/ts/library/Delay";
import AnalyticCounters from "~/chat/ts/service/AnalyticCounters";
import ApiCallback, {
    API_CALLBACK_CONTACTS_UPDATED,
    API_CALLBACK_GET_CONTACTS
} from "~/chat/ts/service/api/methods/ApiCallback";
//import SearchKeyword from "~/chat/ts/service/SearchKeyword";
import {__} from "~/ts/library/Translate";
import CrossTabLock from "~/ts/library/crossTabCommunication/CrossTabLock";
import ObjectHelper from "~/ts/library/ObjectHelper";
import md5 from "~/ts/library/md5";
import {ChatEventManagerInstance, CLIENT_FIELD_CHANGED_EVENT} from "~/chat/ts/ChatEventManager";
import {computed, ref} from "vue";

export const CLIENT_FIELD_DESCR = "descr";
export const CLIENT_FIELD_PHONE = "phone";
export const CLIENT_FIELD_EMAIL = "email";
export const CLIENT_FIELD_CUSTOM_DATA = "customData";

const BAN_KEY = "ban";

function initClientId() {
    let clientId = Cookie.get(CLIENT_ID_COOKIE);
    //console.log("clientId  в cookie = ", clientId);

    if (clientId == null || !clientId.length) {
        clientId = Random.uid(32);
        //console.log("в куках пусто, генерим новый", clientId);
    }

    if (ConfigStore.talkIdFromSetup.value) {
        clientId = ConfigStore.talkIdFromSetup.value;
    } else {
        let fromSetup = ConfigStore.clientIdFromSetup.value;
        if (fromSetup != null) {
            fromSetup = fromSetup.toString();
            if (fromSetup.length > 0) {
                clientId = md5(fromSetup);
                //console.log("в setup было не пусто, взяли оттуда", clientId);
            }
        }
    }

    let date = new Date();
    date.setFullYear(date.getFullYear() + 10);
    Cookie.set(CLIENT_ID_COOKIE, clientId, 86400 * 3650 * 1000 /*(date as any).toGMTString()*/);
    //console.log("записали в cookie clientId = ", clientId)
    return clientId;
}

class Client {
    public countryCode = ref<string>();
    public utm = ref<Dictionary<string>>({});
    public isBannedOnThisTab = ref(false);
    readonly isBanned = computed(() => {
        return !!StorageInstance.getex(BAN_KEY) || this.isBannedOnThisTab.value;
    });
    private clientId = ref<string>();
    readonly id = computed(() => {
        return this.clientId.value;
    });
    private cc = ref<number>();

    public SET_CC(cc: number) {
        this.cc.value = cc;
    }

    public getCc(): number {
        return this.cc.value;
    }

    async initClientId() {
        let locker = new CrossTabLock("initClientId");
        //console.log("лочим initClientId");
        await locker.lock(true);
        //console.log("успешно залочили initClientId");
        this.SET_CLIENT_ID(initClientId());

        await locker.unlock();
        //console.log("разлочили initClientId");


    }

    public SET_UTM(utm: Dictionary<string>) {
        this.utm.value = utm;
    }

    public SET_COUNTRY_CODE(countryCode: string) {
        this.countryCode.value = countryCode;
    }

    public getFieldValue(name: string): any {
        let result = StorageInstance.get(this.getClientFieldKey(name));
        if (result == null) {
            let customData = StorageInstance.get(this.getClientFieldKey("customData"));
            if (customData && customData[name] != null) {
                result = customData[name];
            }
        }
        return result;
    }

    private getClientFieldKey(fieldName: string) {
        return `Client/${fieldName}`;
    }

    public setFieldValue(name: string, value: any) {
        if (JSON.stringify(this.getFieldValue(name)) != JSON.stringify(value)) {
            StorageInstance.set(this.getClientFieldKey(name), value);
            for (let fieldName in ConfigStore.siteParams.value.formElements) {
                if (ConfigStore.siteParams.value.formElements.hasOwnProperty(fieldName)) {
                    ChatEventManagerInstance.emit(CLIENT_FIELD_CHANGED_EVENT, fieldName);
                }
            }
            return true;
        }
        return false;
    }

    public async update(payload: Dictionary<any>, sendCallbacks: boolean = true) {
        let updated: string[] = [];
        for (let key in payload) {
            if (payload.hasOwnProperty(key)) {
                let value = payload[key];
                if (value != null && (typeof value != "string" || value.length)) {
                    if (this.setFieldValue(key, value)) {
                        updated.push(key);
                    }
                }
            }
        }

        if (sendCallbacks) {
            while (true) {
                let cc = this.cc.value;
                if (!cc) {
                    await Delay.make(500);
                } else {
                    let params = ObjectHelper.jsonClone(this.getContactsDictionary());
                    ApiCallback.emit(API_CALLBACK_GET_CONTACTS, params);
                    if (updated.length) {
                        ApiCallback.emit(API_CALLBACK_CONTACTS_UPDATED, params);
                        AnalyticCounters.send("User update contacts", __("EVENT_CLIENT_UPDATE_CONTACTS"));
                        for (let key of updated) {
                            AnalyticCounters.send(`User update field ${key}`);
                        }
                    }
                    break;
                }

            }
        }

    }

    setBan(ban: boolean) {
        this.isBannedOnThisTab.value = ban;
        ban ? StorageInstance.setex(BAN_KEY, ban, 3600000) : StorageInstance.remove(BAN_KEY);
    }

    private SET_CLIENT_ID(id: string) {
        this.clientId.value = id;
    }

    private getContactsDictionary() {
        return {
            id: this.cc.value,
            name: this.getFieldValue(CLIENT_FIELD_DESCR),
            phone: this.getFieldValue(CLIENT_FIELD_PHONE),
            email: this.getFieldValue(CLIENT_FIELD_EMAIL),
            custom: this.getFieldValue(CLIENT_FIELD_CUSTOM_DATA),
            //searchKeyword: SearchKeyword.get(),
            utm: this.utm.value
        };
    }
}

export const ClientStore = new Client()
