import openSupport from "~/chat/ts/service/api/methods/OpenSupport";
import destroyWidget from "~/chat/ts/service/api/methods/Destroy";
import ApiCallback from "~/chat/ts/service/api/methods/ApiCallback";
import getContacts from "~/chat/ts/service/api/methods/GetContacts";
import disallowCobrowse from "~/chat/ts/service/api/methods/DisallowCobrowse";
import openByType from "~/chat/ts/service/api/methods/OpenByType";
import closeMenu from "~/chat/ts/service/api/methods/CloseMenu";
import openMenu from "~/chat/ts/service/api/methods/OpenMenu";
import hideTrigger from "~/chat/ts/service/api/methods/HideTrigger";
import showTrigger from "~/chat/ts/service/api/methods/ShowTrigger";
import setClientInfo from "~/chat/ts/service/api/methods/SetClientInfo";
import openReviewsTab from "~/chat/ts/service/api/methods/OpenReviewsTab";
import getClientIdentifier from "~/chat/ts/service/api/methods/GetClientIdentifier";
import setTarget from "~/chat/ts/service/api/methods/SetTarget";
import Dictionary from "~/ts/library/Dictionary";
import openTab from "~/chat/ts/service/api/methods/OpenTab";
import setDimensions from "~/chat/ts/service/api/methods/SetDimensions";
import setPosition from "~/chat/ts/service/api/methods/SetPosition";
import setTriggerPosition from "~/chat/ts/service/api/methods/SetTriggerPosition";
import sendMessage from "~/chat/ts/service/api/methods/SendMessage";
import receiveMessage from "~/chat/ts/service/api/methods/ReceiveMessage";
import setOperator from "~/chat/ts/service/api/methods/SetOperator";
import listenOperatorsStatus from "~/chat/ts/service/api/methods/ListenOperatorsStatus";
import reload from "~/chat/ts/service/api/methods/Reload";
import fetchReviews from "~/chat/ts/service/api/methods/FetchReviews";
import showTab from "~/chat/ts/service/api/methods/ShowTab";
import removeMessage from "~/chat/ts/service/api/methods/RemoveMessage";
import setMessage from "~/chat/ts/service/api/methods/SetMessage";
import deprecatedMethod from "~/chat/ts/service/api/methods/DeprecatedMethod";
import setDevice from "~/chat/ts/service/api/methods/SetDevice";
import closeSupport from "~/chat/ts/service/api/methods/CloseSupport";
import Url from "~/ts/library/Url";
import {ConfigStore} from "~/chat/ts/store/Config";
import getClientId from "~/chat/ts/service/api/methods/GetClientId";
import openUploadDialog from "~/chat/ts/service/api/methods/OpenUploadDialog";
import attachToOperator from "~/chat/ts/service/api/methods/AttachToOperator";
import setWidgetParams from "~/chat/ts/service/api/methods/SetWidgetParams";
import setOperatorsStatus from "~/chat/ts/service/api/methods/SetOperatorsStatus";
import allMessagesRead from "~/chat/ts/service/api/methods/AllMessagesRead";
import getOperatorGroups from "~/chat/ts/service/api/methods/GetOperatorGroups";
import toggleOperatorGroupVisibility from "~/chat/ts/service/api/methods/ToggleOperatorGroupVisibility";
import getDevice from "~/chat/ts/service/api/methods/GetDevice";
import removeAutoMessages from "~/chat/ts/service/api/methods/RemoveAutoMessages";
import ConsoleWrapper from "~/ts/library/Console";
import getClientSearchId from "~/chat/ts/service/api/methods/GetClientSearchId";
import openWidgetMenu from "~/chat/ts/service/api/methods/OpenWidgetMenu";


export default class Api {
    private static systemMethods = [
        "setDevice", "setWidgetParams", "setOperatorsStatus"
    ];

    public static methods: Dictionary<any> = {
        openSupport,
        closeSupport,
        openUploadDialog,
        destroy: destroyWidget,
        setCallback: ApiCallback.setCallback,
        getContacts,
        disallowCobrowse,
        openByType,
        closeMenu,
        openMenu,
        openWidgetMenu,
        hideTrigger,
        showTrigger,
        setClientInfo,
        getClientInfo: getContacts,
        openReviewsTab,
        getClientIdentifier,
        getClientId,
        getClientSearchId,
        setTarget,
        openTab,
        setDimensions,
        setPosition,
        setTriggerPosition,
        sendMessage,
        receiveMessage,
        setOperator,
        listenOperatorsStatus,
        reload,
        fetchReviews,
        showTab,
        removeMessage,
        setMessage,
        setName: deprecatedMethod("setName", "setClientInfo"),
        changeContacts: deprecatedMethod("changeContacts", "setClientInfo"),
        setCustomData: deprecatedMethod("setCustomData", "setClientInfo"),
        setDevice,
        getDevice,
        attachToOperator,
        setWidgetParams,
        setOperatorsStatus,
        allMessagesRead,
        getOperatorGroups,
        toggleOperatorGroupVisibility,
        removeAutoMessages
    };

    public static init() {

        var queue = [];
        let win = window as any;
        let apiMethod = ConfigStore.setup.value.apiMethod;

        if (win[apiMethod] != null && win[apiMethod].q != null) {
            queue = win[apiMethod].q;
        }

        let $methods = this.methods;
        let $this = this;

        win[apiMethod] = function () {
            var args = arguments;
            var arr = [];
            var name = args[0];
            for (var i = 1; i < args.length; i++) {
                arr.push(args[i]);
            }
            if (name != null && typeof $methods[name] == "function") {
                if ($this.isMethodAvailableByConfig(name)) {
                    if ($this.isMethodAvailableByLicense(name)) {
                        $methods[name].apply(this, arr);
                    } else {
                        ConsoleWrapper.warn(`API-метод ${name} недоступен в бесплатной версии виджета`);
                    }
                } else {
                    ConsoleWrapper.warn("Вызван запрещённый API-метод", name);
                }
            }
            return false;
        };


        let api = win[apiMethod];

        if (win.MeTalk) {
            win.MeTalk = api;
        }

        if (win.TalkMe) {
            win.TalkMe = api;
        }

        if (win.TalkMe && !win.MeTalk) {
            win.MeTalk = win.TalkMe;
        }

        if (win.MeTalk && !win.TalkMe) {
            win.TalkMe = win.MeTalk;
        }

        for (var i = 0; i < queue.length; i++) {
            api.apply(this, queue[i]);
        }

        this.parseHash(apiMethod, api);

        document.addEventListener("click", (e) => {
            let target: HTMLElement = e.target as any;
            while (target) {
                if (target.tagName == "A") {
                    let hash = (target as HTMLLinkElement).href.split("#")[1];
                    if (hash) {
                        if (this.parseHash(apiMethod, api, `#${hash}`)) {
                            e.preventDefault();
                        }
                    }
                    break;
                }
                target = target.parentElement;
            }
        });

        this.watchRootStyles();
    }

    private static rootStylesWatcher: any;

    private static watchRootStyles() {
        if (!this.rootStylesWatcher) {
            this.rootStylesWatcher = setInterval(() => {
                let style = document.getElementById('online-chat-root-styles');
                if (!style) {
                    clearInterval(this.rootStylesWatcher);
                    this.rootStylesWatcher = null
                    reload(() => {
                    });
                }
            }, 100);
        }
    }

    private static isMethodSystem(method: string): boolean {
        return this.systemMethods.includes(method) || !!ConfigStore.setup.value.preview;
    }

    private static isMethodAvailableByConfig(method: string): boolean {
        let params = ConfigStore.siteParams.value.params.api;

        if (this.isMethodSystem(method) || !params) {
            return true;
        }
        if (!params.disabled) {
            return !params.blackListedMethods.includes(method);
        }
        return false;
    }

    private static isMethodAvailableByLicense(method: string): boolean {
        let unavailableBecauseOfFree = !!ConfigStore.siteParams.value.free;
        return !unavailableBecauseOfFree || this.isMethodSystem(method);
    }

    private static parseHash(apiMethod: string, api: any, forcedHash: string = null) {

        let hash = forcedHash ? forcedHash : window.location.hash;

        if (hash.indexOf('#' + [apiMethod] + '/') == 0) {
            let parseURL = new Url("/" + hash.substr(1));
            let pathnameArray = parseURL.pathnameArray;

            if (pathnameArray[1] != null) {
                let asArray = [];
                let queryStringParams = parseURL.parseQueryString();
                for (let key in queryStringParams) {
                    if (queryStringParams.hasOwnProperty(key)) {
                        if (!asArray.length) {
                            asArray.push(parseURL.pathnameArray[1]);
                        }
                        let value = queryStringParams[key];

                        if (!isNaN(parseInt(key))) {
                            key = parseInt(key) as any;
                            asArray.push(value);
                        } else if (value != "undefined") {
                            asArray = null;
                            break;
                        }

                    }
                }
                if (asArray && asArray.length) {
                    api.apply(this, asArray);
                } else {
                    this.prepareQueryParamsDictionary(queryStringParams);
                    api(pathnameArray[1], queryStringParams);
                }
                return true;
            }

        }
        return false;
    }

    private static prepareQueryParamsDictionary(queryStringParams: Dictionary<any>) {
        for (let key in queryStringParams) {
            if (queryStringParams.hasOwnProperty(key)) {
                let split = key.split(".");
                if (split.length > 1) {
                    let params: any = queryStringParams;
                    let splitLength = split.length;
                    for (let i = 0; i < splitLength; i++) {
                        let splitKey = split[i];
                        if (i == splitLength - 1) {
                            params[splitKey] = queryStringParams[key];
                            delete queryStringParams[key];
                        } else {
                            if (!params.hasOwnProperty(splitKey)) {
                                params[splitKey] = {};
                            } else if (typeof params[splitKey] != "object") {
                                break;
                            }
                            params = params[splitKey];
                        }
                    }
                }
            }
        }
    }
}
