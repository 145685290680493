import Dictionary from "~/ts/library/Dictionary";
import {IOperator, IOperatorGroup} from "~/chat/ts/data/IOperator";
import ConsoleWrapper from "~/ts/library/Console";
import {StorageInstance} from "~/ts/library/storage/StorageFactory";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {ConfigStore} from "~/chat/ts/store/Config";
import {OperatorBalanceTypes} from "~/chat/ts/data/ISiteParams";
import Random from "~/ts/library/Random";
import {
    OPERATOR_STATUS_INVISIBLE,
    OPERATOR_STATUS_OFFLINE,
    OPERATOR_STATUS_ONLINE,
    OperatorStatus
} from "~/chat/ts/data/OperatorStatus";
import {computed, ref} from "vue";
import md5 from "~/ts/library/md5";
import {DEFAULT_BOT_AVATAR, STATIC_SERVER} from "~/chat/ts/CommonConstants";


const STORAGE_OPERATORS_GROUP_ID = "operatorGroupId";
const STORAGE_OPERATOR_ID = "operator";
const STORAGE_OPERATOR_STATUS_CACHE = "operatorsStatusCache";


class Operators {
    balancerDisabled = ref(false);
    private temporaryAvailableOperatorSelect = ref(false);

    setTemporaryAvailableOperatorSelect(value: boolean) {
        this.temporaryAvailableOperatorSelect.value = value;
        return this;
    }

    get isOnlineOperatorsSelectAllowed(): boolean {
        return this.temporaryAvailableOperatorSelect.value || ConfigStore.siteParams.value.params.operators.allowChange;
    }

    get isOfflineGroupSelectAllowed(): boolean {
        return this.temporaryAvailableOperatorSelect.value || ConfigStore.siteParams.value.params.leadGen.selectOperatorGroup;
    }


    public initOperatorsStatusFromCache() {
        let statusCache = StorageInstance.getex(STORAGE_OPERATOR_STATUS_CACHE);
        if (statusCache) {
            this.setStatusFromDictionary(statusCache, true);
        }
    }

    isStatusInitialized = ref(false);

    private readonly operatorsById = computed<Dictionary<IOperator>>(() => {
        return ObjectHelper.dictionaryFromArray(
            ConfigStore.operators.value,
            "id"
        );
    });

    private readonly operatorsByLoginHash = computed<Dictionary<IOperator>>(() => {
        return ObjectHelper.dictionaryFromArray(
            ConfigStore.operators.value,
            "loginHash"
        );
    });

    readonly groups = computed<Dictionary<IOperatorGroup>>(() => {
        let result = ConfigStore.siteParams.value ? {...ConfigStore.siteParams.value.operatorsGroups} : {};
        for (let key in result) {
            if (result.hasOwnProperty(key)) {
                if (!this.getOperatorsOfGroup(result[key]).length) {
                    delete result[key];
                }
            }
        }
        return result;
    });

    public TOGGLE_OPERATOR_HIDDEN(payload: { operatorId: string, value: boolean }) {
        let operator = this.getOperatorById(payload.operatorId);
        if (operator) {
            operator.hidden = payload.value;
        }
    }

    /*
    readonly invisibleOperatorsLogins = computed<string[]>(() => {
        return this.invisibleOperatorsList.value.map(operator => operator.username);
    });

     */
    readonly balanceParams = computed(() => {
        return ConfigStore.siteParams.value.params.operators.balance;
    });
    readonly balanceType = computed(() => {
        return OperatorBalanceTypes[this.balanceParams.value.type];
    });
    hasForcedStatus = ref(false);
    readonly groupList = computed(() => {
        return ObjectHelper.arrayFromDictionary(this.groups.value);
    });

    private setSelectedGroup(group?: IOperatorGroup) {
        StorageInstance.set(STORAGE_OPERATORS_GROUP_ID, group ? group.id : null);
    }

    public deselectGroup() {
        this.setSelectedGroup();
    }

    readonly operatorsList = computed(() => {
        return ConfigStore.operators.value;
    });
    readonly hiddenOperatorsList = computed<IOperator[]>(() => {
        return this.operatorsList.value.filter(operator => {
            return this.isOperatorHidden(operator);
        });
    });
    readonly invisibleOperatorsList = computed<IOperator[]>(() => {
        return this.operatorsList.value.filter(operator => {
            return !this.isOperatorVisible(operator);
        });
    });
    readonly onlineAndVisibleGroups = computed(() => {
        return this.groupList.value.filter(group => this.isGroupOnlineAndVisible(group));
    });

    isOperatorInGroup(operator: IOperator, group: IOperatorGroup) {
        return operator.groupID && operator.groupID.indexOf(group.id) > -1;
    }
    readonly visibleGroups = computed(() => {
        let groups = this.groupList.value.filter(group => this.isGroupVisible(group));
        if (groups.length == 1) {
            if (groups[0].virtual) {
                groups = [];
            }
        }
        return groups;
    });

    setStatusFromNodeResponse(response: string) {
        try {
            let operators = JSON.parse(response);
            if (operators != null) {
                this.setStatusFromDictionary(operators);
            }
        } catch (e) {

        }
    }
    readonly visibleOperators = computed(() => {
        return this.operatorsList.value.filter(operator => this.isOperatorVisible(operator));
    });
    readonly onlineOperators = computed(() => {
        return this.operatorsList.value.filter(operator => this.isOperatorOnline(operator));
    });
    readonly isOffline = computed(() => {
        return !this.visibleOperators.value.length;
    });
    readonly groupsAvailableForSelect = computed<IOperatorGroup[]>(() => {
        if (this.isOffline.value) {
            if (this.isOfflineGroupSelectAllowed) {
                return this.visibleGroups.value.filter(group => !group.virtual);
            }
        } else {
            if (this.isOnlineOperatorsSelectAllowed) {
                return this.onlineAndVisibleGroups.value;
            }
        }
        return [];
    });
    readonly selected = computed<{ operator?: IOperator, group?: IOperatorGroup }>(() => {
        let operatorId = StorageInstance.get(STORAGE_OPERATOR_ID);
        if (!operatorId) {
            if (this.balanceType.value.canUseDefaultOperator) {
                operatorId = ConfigStore.siteParams.value.defaultOperatorId;
            }
        }
        let groupId = StorageInstance.get(STORAGE_OPERATORS_GROUP_ID);
        let visibleOperators = this.visibleOperators.value;
        //TODO: убрать про username
        let operator = visibleOperators.find(operator => operator.id == operatorId);
        let group = this.groupsAvailableForSelect.value.find(group => group.id == groupId);
        if (operator) {
            if (!group || !this.isOperatorInGroup(operator, group)) {
                let groupsForSearch = [
                    this.groupsAvailableForSelect.value,
                    this.visibleGroups.value,
                    this.groupList.value
                ];
                for (let item of groupsForSearch) {
                    let visibleGroups = item.filter(visibleGroup => this.isOperatorInGroup(operator, visibleGroup));
                    group = visibleGroups[0];
                    if (group) {
                        break;
                    }
                }
            }
        } else {
            if (this.balanceType.value.selectRandomOperator && visibleOperators.length) {
                let operatorsList = visibleOperators;
                let groupToSelect: IOperatorGroup;
                if (group) {
                    operatorsList = this.getVisibleOperatorsOfGroup(group);
                    groupToSelect = group;
                }
                operator = Random.randomArrayElement(operatorsList);
                if (operator) {
                    this.setSelectedOperator(operator, groupToSelect);
                }
            }
        }

        return {
            operator: operator,
            group: group
        };
    });
    readonly visibleGroupsForCurrentMode = computed(() => {
        return this.isOffline.value ? this.visibleGroups.value : this.onlineAndVisibleGroups.value;
    });
    readonly isSelectedGroupOffline = computed(() => {
        let group = this.selected.value.group;
        return group ? !this.isGroupHasVisibleOperators(group) : this.isOffline.value;
    });
    readonly isSelectOperatorsAvailable = computed(() => {
        let operators = ConfigStore.siteParams.value.params.operators;
        return this.groupsAvailableForSelect.value.length == 0
            && this.visibleOperators.value.length > 1
            && this.isOnlineOperatorsSelectAllowed
            && operators.balance.isOperatorSelectAvailable;
    });
    readonly isSelectGroupAvailable = computed(() => {
        return this.groupsAvailableForSelect.value.length > 1;
    });
    readonly isSelectAvailable = computed(() => {
        return this.isSelectGroupAvailable.value || this.isSelectOperatorsAvailable.value;
    });

    SET_BALANCER_DISABLED(value: boolean) {
        this.balancerDisabled.value = value;
    }

    setSelectedOperator(operator?: IOperator, group?: IOperatorGroup) {
        if (operator) {
            StorageInstance.set(STORAGE_OPERATOR_ID, operator.id);
            if (!group) {
                group = this.selected.value.group;
                if (group && !this.isOperatorInGroup(operator, group)) {
                    group = null;
                }
                if (!group) {
                    group = this.getVisibleGroupsOfOperator(operator)[0];
                    if (!group) {
                        group = this.getAllGroupsOfOperator(operator)[0];
                    }
                }
            }
        } else {
            StorageInstance.set(STORAGE_OPERATOR_ID, null);
        }

        this.setSelectedGroup(group);
    }

    getVisibleOperatorsOfGroup(group: IOperatorGroup) {
        return this.getOperatorsOfGroup(group).filter(operator => this.isOperatorVisible(operator, [group.id]));
    }

    getOnlineOperatorsOfGroup(group: IOperatorGroup) {
        return this.getOperatorsOfGroup(group).filter(operator => this.isOperatorOnline(operator));
    }

    SET_FORCED_STATUS() {
        this.hasForcedStatus.value = true;
    }

    setStatusFromDictionary(operators: Dictionary<OperatorStatus>, fromCache: boolean = false, force: boolean = false) {
        if (!force && this.hasForcedStatus.value) {
            return;
        }
        if (force) {
            this.SET_FORCED_STATUS();
        }
        for (let operatorId in this.operatorsById.value) {
            if (this.operatorsById.value.hasOwnProperty(operatorId)) {
                if (operators[operatorId] == null) {
                    operators[operatorId] = 0;
                }
            }
        }
        for (let operatorId in operators) {
            if (operators.hasOwnProperty(operatorId)) {
                this.SET_STATUS({operatorId, status: operators[operatorId]});
            }
        }
        this.SET_STATUS_INITIALIZED();

        if (!fromCache) {
            StorageInstance.setex(STORAGE_OPERATOR_STATUS_CACHE, operators, 120000);
        }
    }

    getVisibleGroupsOfOperator(operator: IOperator): IOperatorGroup[] {
        return this.onlineAndVisibleGroups.value.filter(group => this.isOperatorInGroup(operator, group));
    }

    getAllGroupsOfOperator(operator: IOperator): IOperatorGroup[] {
        return this.groupList.value.filter(group => this.isOperatorInGroup(operator, group));
    }

    isOperatorOnlineByLogin(login: string): boolean {
        return this.isOperatorOnline(this.getOperatorByLogin(login));
    }

    isOperatorOnline(operator: IOperator): boolean {
        return operator != null && operator.status != OPERATOR_STATUS_OFFLINE;
    }

    private isGroupVisible(group: IOperatorGroup) {
        return !group.hidden || this.isOperatorGroupTemporaryVisible(group);
    }

    private isGroupOnlineAndVisible(group: IOperatorGroup) {
        let result = this.isGroupVisible(group);
        if (result) {
            /*
            * закомментил это после вопроса экзиста, где он просил убрать из выбора группы, где все оффлайн
            * это поведение было в старом виджете.
            * быть может, стоит добавить настройку
            */
            //if (group.virtual) {
            result = this.getVisibleOperatorsOfGroup(group).length > 0;
            //}
        }
        return result;
    }

    private getOperatorTemporaryVisibleKey(id: string) {
        return "operatorTempVisible::" + id;
    }

    private isTemporaryVisibleById(id: string) {
        return StorageInstance.getex(this.getOperatorTemporaryVisibleKey(id)) != null;
    }

    getOperatorsOfGroup(group: IOperatorGroup) {
        return this.operatorsList.value.filter(operator => this.isOperatorInGroup(operator, group));
    }

    unsetOperatorTemporaryVisibleById(id: string) {
        StorageInstance.remove(this.getOperatorTemporaryVisibleKey(id));
    }

    isOperatorTemporaryVisible(operator: IOperator): boolean {
        return this.isTemporaryVisibleById(operator.id);
    }

    setOperatorTemporaryVisible(operator: IOperator, ttlMinus: number) {
        return this.setTemporaryVisibleById(operator.id, ttlMinus);
    }

    unsetOperatorTemporaryVisible(operator: IOperator) {
        return this.unsetOperatorTemporaryVisibleById(operator.id);
    }


    isOperatorGroupTemporaryVisible(group: IOperatorGroup): boolean {
        return this.isTemporaryVisibleById(group.id);
    }

    setOperatorGroupTemporaryVisible(group: IOperatorGroup, ttlMinus: number) {
        return this.setTemporaryVisibleById(group.id, ttlMinus);
    }

    unsetOperatorGroupTemporaryVisible(group: IOperatorGroup) {
        return this.unsetOperatorTemporaryVisibleById(group.id);
    }

    isOperatorHidden(operator: IOperator, allowedHiddenGroupIds: string[] = null) {
        let hidden = operator.hidden || operator.status == OPERATOR_STATUS_INVISIBLE || this.isOperatorExistInHiddenGroups(operator, allowedHiddenGroupIds);
        if (hidden) {
            hidden = !this.isOperatorTemporaryVisible(operator);
        }
        return hidden;
    }

    isOperatorVisible(operator: IOperator, allowedHiddenGroupIds: string[] = null) {
        return operator.status != OPERATOR_STATUS_OFFLINE && !this.isOperatorHidden(operator, allowedHiddenGroupIds);
    }

    isGroupHasVisibleOperators(group: IOperatorGroup): boolean {
        return !!this.visibleOperators.value.find(operator => this.isOperatorInGroup(operator, group));
    }

    isOperatorExistInHiddenGroups(operator: IOperator, allowedHiddenGroupIds: string[] = null) {
        let result = false;
        if (operator.groupID) {
            for (let groupId of operator.groupID) {

                let group = this.groups.value[groupId];
                //this.visibleGroups - опасное место, в плане рекурсии
                if (group) {
                    if (group.hidden) {
                        if (allowedHiddenGroupIds && allowedHiddenGroupIds.indexOf(groupId) > -1) {
                            continue;
                        }
                        result = true;
                    } else if (group.initialHidden) {
                        result = false;
                        break;
                    }
                }
            }
        }
        return result;
    }

    private SET_STATUS_INITIALIZED() {
        this.isStatusInitialized.value = true;
    }

    private SET_STATUS(payload: { operatorId: string, status: OperatorStatus }) {
        if (ConfigStore.setup.value.preview) {
            payload.status = OPERATOR_STATUS_ONLINE;
        }
        let operator = this.operatorsById.value[payload.operatorId];
        if (operator) {
            if (typeof payload.status != "number") {
                ConsoleWrapper.warn("wrong status type", payload.status);
            }
            operator.status = payload.status;
        }
    }

    private setTemporaryVisibleById(id: string, ttlMinus: number) {
        let ttl = ConfigStore.siteParams.value.params.siteChatOptions.canViewInvisibleOperatorTTL * 1000;
        ttl -= ttlMinus;
        if (ttl > 0) {
            StorageInstance.setex(this.getOperatorTemporaryVisibleKey(id), 1, ttl);
        }
    }

    public getOperatorByLogin(login: string): IOperator | null {
        return this.getOperatorByLoginHash(md5(login.toLowerCase()));
    }

    public getOperatorByLoginHash(loginHash: string): IOperator | null {
        return this.operatorsByLoginHash.value[loginHash];
    }

    public getOperatorById(id: string): IOperator | null {
        return this.operatorsById.value[id];
    }

    public getOperatorByIdOrLogin(value: string): IOperator | null {
        let result = this.getOperatorById(value);
        if (!result) {
            result = this.getOperatorByLogin(value);
        }
        return result;
    }

    public getVirtualOperator(fio: string, avatar?: string, isBot?: boolean): IOperator {
        return {
            fio,
            avatar: avatar,
            status: 1,
            username: fio,
            default: false,
            canReceiveLinks: true,
            canViewTyping: true,
            groupID: null,
            hidden: true,
            loginHash: md5(fio.toLowerCase()),
            id: Random.uid(32),
            uid: fio,
            virtual: true,
            isBot
        };
    }

    public getBotOperator() {
        let bot = ConfigStore.siteParams.value.params.bot;
        return this.getVirtualOperator(bot.name, STATIC_SERVER + (bot.avatar ?? DEFAULT_BOT_AVATAR), true);
    }
}

export const OperatorsStore = new Operators();