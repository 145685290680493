import IMessage from "~/chat/ts/data/chat/IMessage";
import {WidgetStore} from "~/chat/ts/store/Widget";
import {ConfigStore} from "~/chat/ts/store/Config";
import ChatAudioNotification from "~/chat/ts/service/ChatAudioNotification";
import WidgetAutoClose from "~/chat/ts/service/WidgetAutoClose";
import MessageStorage from "~/chat/ts/service/messages/MessageStorage";
import {ListenerInstance} from "~/chat/ts/service/ListenerInstance";
import AbstractReceiveOperatorMessageListener from "~/chat/ts/service/listen/AbstractReceiveOperatorMessageListener";
import {ChatTabStore} from "~/chat/ts/store/ChatTab";
import TrackIsOnTab from "~/ts/library/crossTabCommunication/TrackIsOnTab";

export default class MessageListener extends AbstractReceiveOperatorMessageListener {
    private isPlaySound: boolean | null;

    public getAction(): string {
        return "message";
    }

    protected isMarkAsUnreaded(): boolean {
        return true;
    }

    public setPlaySound(value: boolean) {
        this.isPlaySound = value;
        return this;
    }

    public playSound() {
        let url = ConfigStore.setup.value.soundUrl;
        if (!url) {
            ChatAudioNotification.playById(ConfigStore.siteParams.value.params.newMessageNotification.sound);
        } else {
            ChatAudioNotification.play(url);
        }
    }

    afterReceive(message: IMessage) {
        if ((!message.hideNotification && typeof this.isPlaySound != "boolean") || this.isPlaySound) {
            if (!ListenerInstance.isCacheChannel()) {
                if (TrackIsOnTab.isOnTab() || ListenerInstance.isCacheChannelAvailable) {
                    this.playSound();
                }
            }
        }

        if (!message.hideNotification) {
            let autoCloseNotify = ConfigStore.siteParams.value.params.newMessageNotification.autoCloseNotify * 1000;
            if (ConfigStore.openChatOnNewMessage.value) {
                if (!WidgetStore.isOpened.value) {
                    ChatTabStore.toggleOfflineForm(false);
                    WidgetStore.openChatTab();
                    if (autoCloseNotify > 0) {
                        WidgetAutoClose.init(autoCloseNotify);
                    }
                }
            } else {
                if (autoCloseNotify > 0) {
                    setTimeout(() => {
                        if (MessageStorage.isMessageUnreaded(message)) {
                            MessageStorage.hideMessageFromNotification(message);
                        }
                    }, autoCloseNotify);

                }
            }
        }
        super.afterReceive(message);
    }
}