import AbstractAutoAction, {
    IAutoActionSuccessRunResult
} from "~/chat/ts/service/autoActions/actions/AbstractAutoAction";
import {AutoActionModel} from "~/chat/ts/data/AutoActions";
import {WidgetStore} from "~/chat/ts/store/Widget";
import {ConfigStore} from "~/chat/ts/store/Config";
import {IWidgetTabParams, TAB_TYPE_CHAT} from "~/chat/ts/data/ITab";
import ChatAudioNotification from "~/chat/ts/service/ChatAudioNotification";
import WidgetAutoClose from "~/chat/ts/service/WidgetAutoClose";


export default class ShowTab extends AbstractAutoAction<IShowTabParams> {
    constructor(params: IShowTabParams) {
        //превращаем string в boolean для удобства
        params.autoClose = parseInt(params.autoClose as any as string);
        super(params);
    }

    get isNoize(): boolean {
        return true;
    }

    isNeedSendResultToServer(): boolean {
        return true;
    }


    isCanRunNow(): boolean {
        let isOpened = WidgetStore.isOpened.value;
        if (this.isWidgetMenu) {
            return !WidgetStore.isSideMenuOpened.value || !isOpened;
        } else {
            let tab = this.tab;
            if (tab) {
                if (WidgetStore.currentTab.value != tab || !isOpened) {
                    return true;
                }
            }
        }
        return false;
    }

    get tabId(): string {
        if (typeof this.params.tabID == "string") {
            return this.params.tabID;
        } else {
            return this.params.tabID[0];
        }
    }

    get isWidgetMenu(): boolean {
        return this.tabId == "widgetMenu";
    }

    get tab(): IWidgetTabParams | null {
        return ConfigStore.tabsDictionary.value[this.tabId];
    }

    async run(): Promise<IAutoActionSuccessRunResult[]> {
        let result: IAutoActionSuccessRunResult[] = [];

        let isOpened = WidgetStore.isOpened.value;
        ChatAudioNotification.playById(this.params.sound);
        if (this.isWidgetMenu) {
            WidgetStore.open();
            WidgetStore.openSideMenu();
        } else {
            let tab = this.tab;
            if (tab.type == TAB_TYPE_CHAT) {
                WidgetStore.openChatTab();
            } else {
                WidgetStore.openTab(tab);
            }
        }
        result.push({});
        if (!isOpened && this.params.autoClose > 0) {
            WidgetAutoClose.init(this.params.autoClose * 1000);
        }

        return result;
    }
}

type IShowTabParams = AutoActionModel & {
    autoClose: number;
    sound: string
    tabID: string | [string, string]
}
