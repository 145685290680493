import Dictionary from "~/ts/library/Dictionary";

export default class AudioNotification {
    private static cache: Dictionary<HTMLAudioElement> = {};

    public static async play(url: string) {
        //целиком в try catch, потому что в некоторых случаях new Audio кидает Not implemented
        try {
            let audio = this.cache[url];
            if (!audio) {
                audio = new Audio();
                audio.src = url;
                this.cache[url] = audio;
            }

            let result = audio.play();
            if (result) {
                await result;
            }
        } catch (e) {
        }
    }
}


/*
import Dictionary from "~/ts/library/Dictionary";
import EventManager from "~/ts/library/EventManager";

let eventManager = new EventManager();

const PLAY_EVENT = "audioPlayButtonStarted";

export default class AudioPlayer {
    private src: string;

    private static cache: Dictionary<AudioPlayer> = {};

    onPlayEvent: (data: { instance: AudioPlayer }) => void = null;


    public static play(url: string) {
        if (!this.cache[url]) {
            this.cache[url] = new AudioPlayer(url);
        }
        try {
            this.cache[url].play();
        } catch (e) {
            console.log("play exception", e);
        }
    }

    constructor(url: string) {
        this.src = url;

        this.audio = new Audio();

        this.audio.addEventListener("play", () => this.onPlay());
        this.audio.addEventListener("playing", () => this.onPlay());
        this.audio.addEventListener("pause", () => this.onPause());
        this.audio.addEventListener("loadedmetadata", () => this.detectDuration());


        let that = this;
        this.onPlayEvent = function (data) {
            if (data.instance != that) {
                that.pause();
            }
        };
        eventManager.addEventListener(PLAY_EVENT, this.onPlayEvent);
    }


    isPlaying: boolean = false;
    duration?: number = null;

    audio: HTMLAudioElement;

    getFixedDuration(): Promise<number> {
        let audio = this.audio;
        return new Promise(async resolve => {
            let currentTime = this.audio.currentTime;
            let volume = audio.volume;
            let callback = () => {
                let duration = audio.duration;
                if (duration!=Infinity && !isNaN(duration)) {
                    this.duration = audio.duration;
                    audio.removeEventListener("durationchange", callback);
                    try {
                        audio.pause();
                    } catch(e) {

                    }
                    audio.currentTime = currentTime;
                    audio.volume = volume;
                    resolve(duration);
                }
            };
            try {
                audio.addEventListener("durationchange", callback, false);
                audio.load();
                audio.currentTime = 24 * 60 * 60; //fake big time
                audio.volume = 0;
                await audio.play();
            } catch (e) {
            }
        });
    }

    private detectDurationStarted = false;

    async detectDuration() {
        if (!this.detectDurationStarted) {
            this.detectDurationStarted = true;
            let duration = this.audio.duration;
            if (duration == Infinity || isNaN(duration)) {
                duration = await this.getFixedDuration();
            }

            this.duration = isNaN(duration) ? null : Math.round(duration);
            this.detectDurationStarted = false;
        }
    }


    destroy() {
        this.audio.remove();
        eventManager.removeEventListener(PLAY_EVENT, this.onPlayEvent);
    }

    onPlay() {
        this.isPlaying = true;
        eventManager.emit(PLAY_EVENT, {
            instance: this
        });
    }

    onPause() {
        this.isPlaying = false;
    }

    pause() {
        if (this.isPlaying) {
            this.audio.pause();
        }
    }

    async play() {
        if (!this.isPlaying) {
            if (this.audio.src != this.src) {
                this.audio.src = this.src;
            }
            this.audio.currentTime = 0;
            await this.detectDuration();
            console.log("duration is", this.duration);
            this.audio.play();
        }
    }

    onClick() {
        if (this.isPlaying) {
            this.pause();
        } else {
            this.play();
        }
    }

}

 */